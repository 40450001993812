import React from 'react';

function Gaming({ color }) {
    return (







<svg fill={color} width="28" height="28" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
<path d="M8 5.07026C9.17669 4.38958 10.5429 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 10.5429 4.38958 9.17669 5.07026 8" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
<path d="M13.8309 14.9264C8.40242 17.8185 3.18203 18.8529 2.17085 17.2367C1.63758 16.3844 2.38148 14.9651 4 13.3897M17.8486 6.19085C19.8605 5.81929 21.3391 5.98001 21.8291 6.76327C22.6266 8.03781 20.5682 10.5801 17 13.0164" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>
    );
}

export default Gaming;
